import { useCallback } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  concatMap, interval, takeWhile,
} from 'rxjs';

import consultationState from '../stores/consultationState';
import fetchConsultationDetail from '../service/fetchConsultationDetail';

import formatCreatedAt from '../utils/formatCreatedAt';

export default function useConsultation() {
  const [consultation, setConsultation] = useRecoilState(consultationState);

  const initialize = useResetRecoilState(consultationState);

  const startLoading = () => {
    setConsultation((prev) => ({
      ...prev, isLoading: true,
    }));
  };

  const loadConsultation = useCallback((id: string) => {
    setConsultation((prev) => ({
      ...prev, isLoading: true,
    }));

    const subscription = interval(1000)
      .pipe(
        concatMap(() => fetchConsultationDetail(id)),
        takeWhile((consultationDetail) => (
          consultationDetail.isAdvising || consultationDetail.isAnswering
        ), true),
      )
      .subscribe((consultationDetail) => {
        setConsultation(() => ({
          ...consultationDetail,
          isLoading: false,
          isAdvising: consultationDetail.isAdvising,
          isAnswering: consultationDetail.isAnswering,
        }));
      });

    return subscription;
  }, [setConsultation]);

  const formatDate = useCallback(() => (
    formatCreatedAt(consultation.createdAt)
  ), [consultation.createdAt]);

  return {
    consultation,
    userEmail: consultation.userEmail,
    date: formatDate(),
    initialize,
    loadConsultation,
    isLoading: consultation.isLoading,
    isAdvising: consultation.isAdvising,
    isAnswering: consultation.isAnswering,
    startLoading,
  };
}
