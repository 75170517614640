const palette = {
  primary: '#092C86',
  shade2: '#072165',
  textGrey: '#B0B8C1',
  textDarkGrey: '#7D7D7D',
  textBlack: '#333',
  textWhite: '#fff',
  textAlert: '#D8031C',
  containerWhite: '#fff',
  containerLightGrey: '#D9D9D9',
  textLightGrey: '#D9D9D9',
  textTone: '#3F5283',
  kakaoYellow: '#fee500',
  kakaoBlack: '#191919',
  tint9: '#E4E8F2',
  tint7: '#ADB9D7',
  lightTint: '#DCE0EA',
};

export default palette;
