import styled from 'styled-components';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import useResponsive from '../../hooks/useResponsive';

import CloseIcon from './CloseIcon';
import ModalActionButton from './ModalActionButton';

const Container = styled.div`
  padding: 0.5em;
  padding-bottom: 1em; 
  min-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  border-radius: 10px;
  border: 0.5px solid #D9D9D9;
  background-color: ${palette.containerWhite};

  ${media.isMobile`
    padding: 1em;
    align-items: flex-start;
  `}

  p {
    ${typography.body1}
    white-space: pre-wrap;
    text-align: center;

    ${media.isMobile`
      ${typography.body1_mobile}
      padding-inline: 0.5em;
      text-align: left;
    `}
  }
`;

const ButtonSection = styled.section`
  display: flex;
  justify-content: center;
  gap: 1em;
  
  ${media.isMobile`
    width: 100%;
    flex-direction: column-reverse;
    gap: 0.5em;
  `}
`;

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  ${media.isMobile`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  `}

  div {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ${media.isMobile`
      width: fit-content;
    `}
  }

  h1 {
    ${typography.heading3}
    ${media.isMobile`
      ${typography.heading2_mobile}
      padding-inline: 0.5em;
    `}
  }
`;

const KeepGoingButton = styled(ModalActionButton)`
  color: ${palette.textDarkGrey};
  background-color: ${palette.lightTint};

  &:hover {
    outline: 1px solid ${palette.tint7};
  }
`;

const RewriteButton = styled(ModalActionButton)``;

export default function InquiryContentModal({ onClose, onSubmit } : {
  onClose : () => void;
  onSubmit : () => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <Container>
      <Head>
        <div>
          <CloseIcon onClick={onClose} />
        </div>
        <h1>
          글자 수가 부족합니다.
        </h1>
      </Head>
      <p>
        이대로도 질문을 등록하실 수 있지만,
        {'\n'}
        답변이 부족하거나 부정확할 수 있습니다.
        {'\n\n'}
        (권장: 150자 이상)
      </p>
      <ButtonSection>
        <RewriteButton id="inquiry-modal-button" className="rewrite" type="button" onClick={onClose}>
          {isMobile ? '네, 다시 써볼게요' : '다시쓰기'}
        </RewriteButton>
        <KeepGoingButton id="inquiry-modal-button" className="keep-going" type="button" onClick={onSubmit}>
          {isMobile ? '이대로 문의할게요' : '이대로 문의하기'}
        </KeepGoingButton>
      </ButtonSection>
    </Container>
  );
}
