import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

import assetSource from '../../data/assetSource';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.kakaoYellow};
  color: ${palette.kakaoBlack};
  padding: 0.75em;
  border-radius: 10px;
  width: 294px;
  gap: 0.5em;
  cursor: pointer;

  img {
    width: 36px;
    height: auto; 
  }
  
  span { 
    ${typography.body1Bold}
  }

  ${media.isMobile`
    span {
      ${typography.body1Bold}
    }
  `}
`;

export default function KakaoLoginButton({ id, className, onClick } : {
  id: string;
  className: string;
  onClick: () => void;
}) {
  return (
    <Button id={id} className={className} type="button" onClick={onClick}>
      <img
        src={`${assetSource}/assets/kakao-icon.png`}
        alt="카카오"
      />
      <span>카카오로 계속하기</span>
    </Button>
  );
}
