import bokeAPI from './bokeAPI';

export default async function requestLogin(
  authorizationCode: string,
  guestId: string,
) {
  const { data } = await bokeAPI.post<string>('/sessions', {
    authorizationCode,
    guestId,
  });
  return data;
}
