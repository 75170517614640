import { atom } from 'recoil';

import Consultation from '../types/Consultation';

const consultationState = atom<Consultation>({
  key: 'consultationState',
  default: {
    id: '',
    userEmail: '',
    createdAt: null,
    mainConsultation: {
      inquiry: {
        title: '',
        content: '',
      },
      advice: '',
    },
    additionalConsultation: [],
    isAdvising: false,
    isAnswering: false,
    isLoading: false,
  },
});

export default consultationState;
