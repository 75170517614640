const typography = {
  heading1: `
    font-size: 32px;
    font-weight: 700;
    line-height: 130%;
  `,
  heading1_mobile: `
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
  `,
  heading2: `
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; 
  `,
  heading2_mobile: `
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
  `,
  heading3: `
    font-size: 20px;
    font-weight: 700;
    line-height: 150%; 
  `,
  heading3_mobile: `
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  `,
  heading4: `
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  `,
  heading4_mobile: `
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  `,
  body1: `  
    font-size: 16px;
    font-weight: 400;
    line-height: 165%;
  `,
  body1Bold: `
    font-size: 16px;
    font-weight: 700;
    line-height: 165%;
  `,
  body1_mobile: `
    font-size: 16px;
    font-weight: 400;
    line-height: 165%;
  `,
  body1_mobile_button: `
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
  `,
  body2_mobile: `
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
  `,
  body3: `
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
  `,
  body3Bold: `
    font-size: 13px;
    font-weight: 700;
    line-height: 150%;
  `,
  body3Bold_mobile: `
    font-size: 13px;
    font-weight: 700;
    line-height: 150%;
  `,
  caption: `
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  `,
  caption_mobile: `
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  `,
  captionBold_mobile: `
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
  `,
};

export default typography;
