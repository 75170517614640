import { atom } from 'recoil';

import ConsultationCaseState from '../types/ConsultationCaseState';

const consultationCaseState = atom<ConsultationCaseState>({
  key: 'consultationCaseState',
  default: {
    isLastPage: false,
    currentPage: 1,
    createdAtBefore: '',
    consultationCases: [],
  },
});

export default consultationCaseState;
