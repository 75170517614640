import styled from 'styled-components';

import palette from '../styles/palette';
import typography from '../styles/typography';

import Sidebar from './Sidebar';

import useMainConsultation from '../hooks/useMainConsultation';
import useConsultation from '../hooks/useConsultation';

import media from '../styles/media';

const Container = styled.div`
  display: flex;
  padding: 2em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;
  gap: 0.5em;

  ${media.isMobile`
    padding: 1em 0.5em 0em 0.5em;
    border: 0;
    border-radius: 0;
  `}
`;

const InquiryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  
  h2 {
    ${typography.heading3}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.heading2_mobile}
    `}
  }
  
  section {
    ${typography.caption}
    display: flex;
    gap: 0.6em;
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.caption_mobile}
    `}
  }  
`;

const Content = styled.div`
  white-space: pre-wrap;
  overflow-wrap: anywhere;

  ${typography.body1}
  color: ${palette.textBlack};

  ${media.isMobile`
    ${typography.body1_mobile}
  `}
`;

export default function InquirySection() {
  const { inquiry } = useMainConsultation();

  const { userEmail, date } = useConsultation();

  return (
    <Container>
      <Sidebar />
      <InquiryContainer>
        <h2>
          {inquiry.title}
        </h2>
        <section>
          <p>{userEmail}</p>
          <p>{date}</p>
        </section>
        <Content>
          {inquiry.content}
        </Content>
      </InquiryContainer>
    </Container>
  );
}
