import bokeAPI from './bokeAPI';

export default async function checkConsultationAuthorization(
  { guestId, consultationId }:
  { guestId: string; consultationId: string; },
): Promise<boolean> {
  const response = await bokeAPI.post(`/consultations/${consultationId}/authorize`, {
    guestId,
  });

  return response.data;
}
