import styled from 'styled-components';

import palette from '../styles/palette';
import typography from '../styles/typography';
import media from '../styles/media';

import Link from './common/Link';

import MobileHeaderLeftButton from './MobileHeaderLeftButton';
import PCHeaderRightButton from './PCHeaderRightButton';

import assetSource from '../data/assetSource';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding-block: 0.5em;
  padding-inline: 2em;

  ${media.isMobile`
    padding-inline: 1em;
  `}

  display: flex;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};

  ${media.isMobile`
    justify-content: space-between;
  `}

  nav {
    ul {
      display: flex;
      gap: 1em;

      ${media.isMobile`
        li:last-of-type {
          display: none;
        }
      `}
    }
  }
`;

const Title = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  
  img {
    height: 1.5em;
    width: auto;

    ${media.isMobile`
      display: none;
    `}
  }

  h1 {
    color: ${palette.primary};
    ${typography.heading4}
  }
`;

const Button = styled(Link)`
  img {
    vertical-align: top;
    height: 1.5em;
    width: auto;
    margin-right: 0.625em;
  }

  ${media.isMobile`
    display: none;
  `}

`;

const MobileNewConsultationButton = styled.a`
  display: none;

  ${media.isMobile`
    display: inline;
    img {
      display: block;
      height: 1.875em;
      width: auto;
    }
  `}
`;

export default function Header({ isPcHomepage }: {
  isPcHomepage: boolean;
}) {
  return (
    <Container>
      <MobileHeaderLeftButton />
      <Title href="/">
        <img alt="노크 로고" src={`${assetSource}/assets/logo-default.png`} />
        <h1>노크 - AI 법률상담</h1>
      </Title>
      <nav>
        <ul>
          <li>
            {isPcHomepage || (
            <Button id="new-consultation-button" href="/">
              <img alt="새로운 상담" src={`${assetSource}/assets/new-consultation.png`} />
              <span>새로운 상담하기</span>
            </Button>
            )}

            <MobileNewConsultationButton id="new-consultation-button" href="/?status=newConsultation">
              <img alt="새로운 상담" src={`${assetSource}/assets/new-consultation-fill.png`} />
            </MobileNewConsultationButton>
          </li>
          <li>
            <PCHeaderRightButton />
          </li>
        </ul>
      </nav>
    </Container>
  );
}
