import styled from 'styled-components';

import assetSource from '../../data/assetSource';

const CloseButton = styled.button`
  padding: 0.25em;
  background-color: transparent;
  cursor: pointer;
  
  img {
    display: block;
  }
`;

export default function CloseIcon({ onClick } : {
  onClick: () => void;
}) {
  return (
    <CloseButton type="button" onClick={onClick}>
      <img src={`${assetSource}/assets/close-icon.svg`} alt="닫기" />
    </CloseButton>
  );
}
