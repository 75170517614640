import { ComponentType } from 'react';
import { atom } from 'recoil';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ModalComponent = ComponentType<any>;

export interface ModalType {
  Component: ModalComponent,
  isOpen: boolean,
  props: {
    onSubmit?: () => void;
  }
}

const modalState = atom<ModalType | null>({
  key: 'modalState',
  default: null,
});

export default modalState;
