import Consultation from '../types/Consultation';
import bokeAPI from './bokeAPI';

export default async function fetchConsultationDetail(id: string): Promise<Consultation> {
  const { data } = await bokeAPI.get(`/consultations/${id}`);

  return {
    ...data,
    mainConsultation: {
      inquiry: data.mainConsultation.inquiry,
      advice: data.mainConsultation.advice.content,
    },
    createdAt: new Date(data.createdAt),
  };
}
