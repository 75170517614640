import { useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import modalState, { ModalComponent } from '../stores/modalState';

export default function useModal() {
  const setModalState = useSetRecoilState(modalState);

  const openModal = useCallback(({ Component, onSubmit }: {
    Component : ModalComponent;
    onSubmit?: () => void;
  }) => {
    setModalState({
      Component,
      isOpen: true,
      props: { onSubmit },
    });
  }, [setModalState]);

  return { openModal };
}
