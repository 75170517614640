import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

import InquiryInput from '../InquiryInput';

import useMainConsultation from '../../hooks/useMainConsultation';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 90svh;

  background-color: ${palette.containerWhite};
  border-radius: 0.625em 0.625em 0 0;
  border: 0.03125em solid ${palette.containerLightGrey};
  box-shadow: 0 -0.125em 0.625em 0 rgba(0, 0, 0, 0.5);

  animation: float 0.3s ease;
  
  @keyframes float {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em 0.75em 0.25em;
  border-bottom: 0.03125em solid ${palette.containerLightGrey};
`;

const CloseButton = styled.button`
  padding: 0.5em 0.75em;
  color: ${palette.primary};
  background-color: transparent;
  ${typography.body1_mobile_button}
`;

const SubmitButton = styled.button<{ inactive : boolean }>`
  padding: 0.5em 0.75em;
  border-radius: 0.5em;
  background-color: ${palette.primary};
  color: ${palette.textWhite};
  ${typography.body1_mobile_button}

  background-color: ${({ inactive }) => (inactive ? palette.containerLightGrey : palette.primary)};
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
`;

const Title = styled.h4`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${palette.primary};
  ${typography.heading4_mobile}
`;

const Body = styled.div`
  width: 100%;
  padding: 0.75em 0.75em 2em 0.75em;
  overflow: scroll;
`;

const Background = styled.div`
  position: fixed;
  z-index: 7;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
`;

export default function MobileInquiryForm({ onClose } : {
  onClose: () => void;
}) {
  const { consult, inquiry } = useMainConsultation();
  const isEmpty = inquiry.content.length === 0;

  return (
    <>
      <RemoveScroll>
        <Container>
          <Header>
            <CloseButton onClick={onClose}>취소</CloseButton>
            <Title>
              새로운 질문
            </Title>
            <SubmitButton
              inactive={isEmpty}
              onClick={consult}
            >
              질문하기
            </SubmitButton>
          </Header>
          <Body>
            <InquiryInput />
          </Body>
        </Container>
      </RemoveScroll>
      <Background />
    </>
  );
}
