import styled from 'styled-components';

import { useEffect, useState } from 'react';

import {
  concat, interval, map, takeWhile,
  generate,
  concatMap,
} from 'rxjs';

import { range } from 'ramda';
import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import useConsultation from '../../hooks/useConsultation';

import LoadingIcon from '../common/LoadingIcon';

const AdviceContainer = styled.section`  
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  p {
    min-height: 1.7em;
    color: ${palette.textBlack};
    ${typography.body1}
    display: flex;
    align-items: center;
    gap: 8px;

    ${media.isMobile`
      ${typography.body1_mobile}
    `}
  }
`;

const AnswerContainer = styled(AdviceContainer)`
  min-height: 6.25em;

  ${media.isMobile`
    min-height: 5em;
  `}
`;

const crescendo = (sentence: string) => range(0, sentence.length);
const fermata = (sentence: string) => Array(30).fill('').map(() => sentence.length);
const decrescendo = (sentence: string) => [...crescendo(sentence)].reverse();

const sequencePlot = (sentence: string) => [
  ...crescendo(sentence),
  ...fermata(sentence),
  ...decrescendo(sentence),
];

const playProgressObservable = (sentence: string) => interval(50).pipe(
  takeWhile((i) => i < sequencePlot(sentence).length),
  map((i) => sentence.slice(0, sequencePlot(sentence)[i])),
);

export default function ProgressSection() {
  const { isAdvising } = useConsultation();

  const [progress, setProgress] = useState('');

  useEffect(() => {
    const sentences = [
      '엘리먼츠 AI를 통해 사건을 정리 중입니다.',
      '관련된 법령과 판례를 다시 읽어보고 있습니다.',
      '엘리먼츠 AI로 사건을 분석하고 있습니다',
      '유사 사례를 확인 중입니다.',
      '엘리먼츠 AI로 법령을 분석하고 있습니다.',
      '법전을 다시 살펴보고 있습니다.',
      '엘리먼츠 AI로 법률 조언을 작성하고 있습니다.',
      '민법과 형법을 분석하고 있습니다.',
      '엘리먼츠 AI로 판례평석을 읽어보고 있습니다.',
      '법원에 잠시 다녀오고 있습니다.',
      '리걸테크 법안이 국회를 통과하고 있습니다.',
    ];

    const iterateManyObservable = (count: number) => generate({
      initialState: 0,
      condition: (x) => x < count,
      iterate: (x) => x + 1,
    });

    const playObservable = () => concat(
      ...sentences.map(
        playProgressObservable,
      ),
    );

    const subscription = iterateManyObservable(10000)
      .pipe(concatMap(playObservable))
      .subscribe((p) => { setProgress(p); });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (isAdvising) {
    return (
      <AdviceContainer>
        <p>
          <LoadingIcon />
          {progress}
        </p>
      </AdviceContainer>
    );
  }

  return (
    <AnswerContainer>
      <p>
        <LoadingIcon />
        {progress}
      </p>
    </AnswerContainer>
  );
}
