import styled from 'styled-components';

import throttle from 'lodash.throttle';

import useResponsive from '../hooks/useResponsive';

import MobileActionButton from './MobileActionButton';
import PCSubmitButton from './common/PCSubmitButton';

import assetSource from '../data/assetSource';

const Container = styled.div`
  padding: 0.5em;
  display: flex;
  justify-content: flex-end;
`;

export default function InquirySubmit({ onSubmit, isEmpty }: {
  onSubmit: () => void;
  isEmpty: boolean;
}) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <MobileActionButton onSubmit={onSubmit} status={isEmpty ? 'isEmpty' : 'inquiry'} />
    );
  }

  const handleClick = throttle(() => {
    onSubmit();
  }, 1000);

  return (
    <Container>
      <PCSubmitButton
        type="button"
        onClick={handleClick}
        inactive={isEmpty}
      >
        <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
        {isEmpty ? '내용을 입력해주세요' : '질문하기'}
      </PCSubmitButton>
    </Container>
  );
}
