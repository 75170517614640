import styled from 'styled-components';

import { useCallback, useEffect, useState } from 'react';

import useConsultation from '../hooks/useConsultation';
import useQueryParams from '../hooks/useQueryParams';
import useResponsive from '../hooks/useResponsive';
import useMainConsultation from '../hooks/useMainConsultation';
import useModal from '../hooks/useModal';

import KinWelcomeModal from '../components/modal/KinWelcomeModal';
import MobileActionButton from '../components/MobileActionButton';
import InquiryForm from '../components/InquiryForm';
import Loading from '../components/common/PageLoading';
import BrowsingSection from '../components/homepage/BrowsingSection';
import MobileInquiryForm from '../components/homepage/MobileInquiryForm';
import SectionTitle from '../components/common/SectionTitle';
import HomePageSectionLayout from '../components/homepage/HomePageSectionLayout';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2em;
`;

export default function HomePage() {
  const { isLoading, initialize } = useConsultation();
  const { updateContent } = useMainConsultation();
  const { openModal } = useModal();
  const { isMobile } = useResponsive();
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const { naverInquiry, status } = useQueryParams();

  const autoWrite = useCallback((inquiry : string) => {
    updateContent(inquiry);
    openModal({
      Component: KinWelcomeModal,
    });
  }, [openModal, updateContent]);

  useEffect(() => {
    initialize();
    if (naverInquiry) {
      autoWrite(naverInquiry);
    }
  }, [autoWrite, initialize, naverInquiry, updateContent]);

  useEffect(() => {
    if (status === 'newConsultation') {
      setIsFormOpen(true);
    }
  }, [status]);

  const onClose = () => {
    setIsFormOpen(false);
  };

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (isMobile) {
    return (
      <Container>
        <MobileActionButton status="isHomepage" onSubmit={() => setIsFormOpen(true)} />
        {isFormOpen && <MobileInquiryForm onClose={onClose} />}
        <BrowsingSection />
      </Container>
    );
  }

  return (
    <Container>
      <HomePageSectionLayout>
        <SectionTitle title="문의하기" />
        <InquiryForm />
      </HomePageSectionLayout>
      <BrowsingSection />
    </Container>
  );
}
