import styled from 'styled-components';

import palette from '../../styles/palette';

const Container = styled.span`
  display: flex;
  align-items: center;

  span {
    display: block;
    width: 0.25em;
    aspect-ratio: 1;
    margin: 1px;
    border-radius: 100%;
    background-color: ${palette.primary};

    animation: bounce 0.6s infinite;

    &:nth-of-type(2) {
      animation-delay: 0.07s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.14s;
    }

    @keyframes bounce {
      0% {
        transform: translateY(-70%);
      }

      50% {
        transform: translateY(70%);
      }

      100% {
        transform: translateY(-70%);
      }
    }
  }
`;

export default function LoadingIcon() {
  return (
    <Container>
      <span />
      <span />
      <span />
    </Container>
  );
}
