import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';

import Button from '../common/Button';
import {
  AnswerSidebar,
} from '../Sidebar';

import MobileActionButton from '../MobileActionButton';
import LoginSection from './LoginSection';
import ProgressSection from './ProgressSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const LoadingContent = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;
  
  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4_mobile}
    `}
  }

  div {
    color: ${palette.textBlack};
    ${typography.body1}
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  ${media.isMobile`
    gap: 0.5em;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const LoadingButton = styled(Button)`
  background-color: ${palette.containerLightGrey};

  &:hover {
    background-color: ${palette.containerLightGrey};
    box-shadow: none;
  }
`;

function Loading() {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <MobileActionButton status="loading" onSubmit={() => {}} />
    );
  }
  return (
    <ButtonContainer>
      <LoadingButton
        type="button"
      >
        답변 중...
      </LoadingButton>
    </ButtonContainer>
  );
}

export default function AdviceAnswerLoading() {
  const { isLoggedIn } = useAuth();

  return (
    <Container>
      <LoadingContent>
        <AnswerSidebar />
        <Content>
          <h4>벤자민 - AI 변호사</h4>
          <Body>
            <ProgressSection />
            <LoginSection isHidden={isLoggedIn()} />
          </Body>
        </Content>
      </LoadingContent>
      <Loading />
    </Container>
  );
}
