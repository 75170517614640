import { useCallback, useState } from 'react';

import { useRecoilState } from 'recoil';

import fetchPaginatedConsultations from '../service/fetchPaginatedConsultations';

import consultationCaseState from '../stores/consultationCaseState';

export default function useConsultationCases() {
  const [state, setConsultationCaseState] = useRecoilState(consultationCaseState);
  const [isLoading, setIsLoading] = useState(false);

  const loadConsultationCases = useCallback(async () => {
    setIsLoading(true);
    const now = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '000+00:00');

    const { isLastPage, consultationCases } = await fetchPaginatedConsultations({
      page: 1,
      createdAtBefore: now,
    });

    setConsultationCaseState({
      isLastPage,
      currentPage: 1,
      createdAtBefore: now,
      consultationCases,
    });
    setIsLoading(false);
  }, [setConsultationCaseState]);

  const appendConsultationCases = useCallback(async () => {
    setIsLoading(true);
    const nextPage = state.currentPage + 1;

    const { isLastPage, consultationCases } = await fetchPaginatedConsultations({
      page: nextPage,
      createdAtBefore: state.createdAtBefore,
    });

    setConsultationCaseState({
      ...state,
      isLastPage,
      currentPage: nextPage,
      consultationCases: [...state.consultationCases, ...consultationCases],
    });
    setIsLoading(false);
  }, [setConsultationCaseState, state]);

  return {
    consultationCases: state.consultationCases,
    isLastPage: state.isLastPage,
    loadConsultationCases,
    appendConsultationCases,
    isLoading,
  };
}
