import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

const ModalActionButton = styled.button`
  min-width: 100px;
  padding-block: 8px;
  padding-inline: 16px;
  border-radius: 5px;
  background: ${palette.primary};
  ${typography.body1Bold}
  color: ${palette.textWhite};
  cursor: pointer;

  ${media.isMobile`
    width: 100%;
  `}

  @media(hover: hover) {
    &:hover {
      color: ${palette.primary};
      border-radius: 5px;
      outline: 1px solid ${palette.primary};
      background-color: ${palette.containerWhite};
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
    }
  }
`;

export default ModalActionButton;
