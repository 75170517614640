import styled from 'styled-components';

import { useEffect } from 'react';

import SyncLoader from 'react-spinners/SyncLoader';

import palette from '../../styles/palette';
import media from '../../styles/media';

import Case from './Case';
import SectionTitle from '../common/SectionTitle';
import HomePageSectionLayout from './HomePageSectionLayout';

import useResponsive from '../../hooks/useResponsive';
import useConsultationCases from '../../hooks/useConsultationCases';
import useScrollToBottom from '../../hooks/useScrollToBottom';

const LoadingContainer = styled.div<{ isFirst: boolean }>`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isFirst }) => media.isMobile`
    height: ${isFirst ? '80vh' : '10vh'};
  `}
`;

const Cases = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  ${media.isMobile`
    gap: 0.5em;
  `}
`;

export default function BrowsingSection() {
  const { isMobile } = useResponsive();

  const {
    consultationCases,
    isLastPage,
    loadConsultationCases,
    appendConsultationCases,
    isLoading,
  } = useConsultationCases();

  useEffect(() => {
    if (consultationCases.length) return;

    loadConsultationCases();
  }, [consultationCases, loadConsultationCases]);

  useScrollToBottom(() => {
    if (isLoading || isLastPage) return;
    appendConsultationCases();
  });

  return (
    <HomePageSectionLayout>
      {isMobile || (
        <SectionTitle title="상담사례 보기" />
      )}
      <Cases>
        {
          consultationCases.map((consultationCase, index) => (
            <Case
              key={consultationCase.id}
              consultationCase={consultationCase}
              isFirstItem={index === 0}
            />
          ))
        }
      </Cases>
      {isLoading
        ? (
          <LoadingContainer isFirst={consultationCases.length === 0}>
            <SyncLoader color={palette.primary} size={20} />
          </LoadingContainer>
        )
        : null}
    </HomePageSectionLayout>
  );
}
