import styled from 'styled-components';

import palette from '../../styles/palette';

import Button from './Button';

const PCSubmitButton = styled(Button)<{ inactive : boolean; }>`
  display: flex;
  gap: 10px;
  align-items: center;
  
  background-color: ${({ inactive }) => (inactive ? palette.containerLightGrey : palette.primary)};
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
`;

export default PCSubmitButton;
