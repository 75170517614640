import { useCallback } from 'react';

import { useLocalStorage } from 'usehooks-ts';
import postVisit from '../service/postVisit';
import useAuth from './useAuth';

export default function useVisit() {
  const [storedId, setGuestId, removeGuestId] = useLocalStorage('GUEST_ID', '');
  const { isLoggedIn } = useAuth();

  const visit = useCallback(async () => {
    if (storedId || isLoggedIn()) {
      return;
    }

    const guestId = await postVisit();
    setGuestId(guestId);
  }, [storedId, setGuestId, isLoggedIn]);
  return {
    visit,
    guestId: storedId,
    removeGuestId,
  };
}
