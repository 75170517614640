import styled from 'styled-components';

import useLogin from '../../hooks/useLogin';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

import KakaoLoginButton from '../common/KakaoLoginButton';

const Container = styled.section`
  width: fit-content;
  padding-block: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-top: 1px solid ${palette.containerLightGrey};

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}
  }

  ${media.isMobile`
    padding: 0.5em;
    align-items: center;
    gap: 0.625em;
    
    h4 {
      ${typography.heading4_mobile}
    }
  `}
`;

export default function LoginSection({ isHidden } : {
  isHidden: boolean;
}) {
  const { navigateLogin } = useLogin();

  if (isHidden) return null;

  return (
    <Container>
      <h4>3초 만에 로그인하고 무제한 무료로 질문하세요</h4>
      <KakaoLoginButton id="login-button" className="in-advising" onClick={navigateLogin} />
    </Container>
  );
}
