import { useRecoilValue, useResetRecoilState } from 'recoil';

import ReactModal from 'react-modal';

import modalState from '../../stores/modalState';

export default function Modal() {
  const modal = useRecoilValue(modalState);
  const resetModal = useResetRecoilState(modalState);

  if (!modal) return null;

  const { Component, isOpen, props } = modal;

  const onClose = () => {
    resetModal();
  };

  const { onSubmit } = props;

  const handleSubmit = async () => {
    onClose();

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="modal"
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',

          width: 'fit-content',
          height: 'fit-content',
          padding: 0,

          borderRadius: 0,
          border: 'none',
          backgroundColor: 'transparent',
          zIndex: 10,
        },
        overlay: {
          backgroundColor: '#000000cc',
          zIndex: 9,
        },

      }}
    >
      {onSubmit ? (
        <Component onClose={onClose} onSubmit={handleSubmit} />
      ) : (
        <Component onClose={onClose} />
      )}
    </ReactModal>
  );
}
