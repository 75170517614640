import throttle from 'lodash/throttle';
import useScroll from './useScroll';

export default function useScrollToBottom(onScroll: () => void) {
  const handleScroll = () => {
    const paddingBottom = 1000;
    const isBottomTouched = window.innerHeight + window.scrollY + paddingBottom
      >= document.body.scrollHeight;

    if (isBottomTouched) {
      onScroll();
    }
  };

  useScroll(throttle(handleScroll, 100));
}
