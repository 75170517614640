import styled from 'styled-components';

import palette from '../styles/palette';
import media from '../styles/media';

import Answer from './Answer';
import Question from './Question';
import QuestionForm from './QuestionForm';
import AdviceAnswerLoading from './answerLoading/AdviceAnswerLoading';

import useLogin from '../hooks/useLogin';
import useMainConsultation from '../hooks/useMainConsultation';
import useAdditionalConsultation from '../hooks/useAdditionalConsultation';
import useConsultation from '../hooks/useConsultation';
import useAuth from '../hooks/useAuth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;

  ${media.isMobile`
    padding: 1em 0.5em;
    border: 0;
    border-radius: 0;
  `}
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  ${media.isMobile`
    gap: 1em;
  `}
`;

export default function AnswerSection({
  isConsultationAuthorized,
}: {
  isConsultationAuthorized: boolean;
}) {
  const { consultation } = useConsultation();

  const { advice, isAdvising } = useMainConsultation();
  const { messages, ask, isAnswering } = useAdditionalConsultation();

  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();

  const handleSubmit = async () => {
    const isTrialEnd = messages.length === 0;

    if (isTrialEnd && !isLoggedIn()) {
      navigateLogin();
      return;
    }

    await ask(consultation.id);
  };

  return (
    <Container>
      <Messages>
        <Answer content={advice} isAdvice />
        {
          messages.map((message, index) => {
            const questionIndex = index / 2 + 1;

            return (
              message.type === 'question'
                ? (
                  <Question
                    order={questionIndex + 1}
                    content={message.content}
                  />
                )
                : <Answer content={message.content} isAdvice={false} />
            );
          })
        }
        {(isAdvising || isAnswering)
          ? <AdviceAnswerLoading /> : (
            <QuestionForm
              order={messages.length / 2 + 2}
              onSubmit={handleSubmit}
              isConsultationAuthorized={isConsultationAuthorized}
            />
          )}
      </Messages>
    </Container>
  );
}
