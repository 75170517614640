import bokeAPI from './bokeAPI';

export default async function createConsultation(content: string, guestId: string) {
  const { data } = await bokeAPI.post('/consultations', {
    inquiryContent: content,
    guestId,
  });

  return data;
}
