import styled from 'styled-components';

import media from '../styles/media';

import useAuth from '../hooks/useAuth';
import useLogin from '../hooks/useLogin';

import Link from './common/Link';

const Button = styled(Link)`
  display: block;

  ${media.isMobile`
    display: none;
  `}

`;

export default function PCHeaderRightButton() {
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();

  return (
    isLoggedIn()
      ? (
        <Button id="mypage-button" href="/consultations">
          상담기록
        </Button>
      )
      : (
        <Button id="login-button" className="header" type="button" onClick={navigateLogin}>
          로그인/회원가입
        </Button>
      )
  );
}
