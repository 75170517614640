import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import Sidebar, { AnswerSidebar } from '../Sidebar';

import ConsultationCase from '../../types/ConsultationCase';

const Container = styled.div<{ isFirstItem: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 2em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;
  gap: 2em;
  cursor: pointer;
  
  ${({ isFirstItem }) => media.isMobile`
    padding: 0.75em 0.5em;    
    border: 0;
    border-top: ${isFirstItem ? 'none' : `1px solid ${palette.containerLightGrey}`};
    border-bottom: 1px solid ${palette.containerLightGrey};
    border-radius: 0;
    box-shadow: ${isFirstItem ? '0px 6px 10px 0px rgba(0, 0, 0, 0.10)' : '0px 0px 10px 0px rgba(0, 0, 0, 0.30)'};
    gap: 1em;
  `}
`;

const InquiryContainer = styled.div`
  display: flex;
  gap: 0.5em;
`;

const AnswerContainer = styled.div`
  display: flex;
  gap: 0.5em;
`;

const UserInfo = styled.div`
  display: flex;
  gap: 0.625em;

  > p {
    ${typography.caption}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.caption_mobile}
    `}
  }  
`;

const InquiryContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.textBlack};
  width: 100%;
  gap: 0.5em;

  > h4 {
    ${typography.heading3}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.heading2_mobile}
    `}
  }

  > p {
    ${typography.body1}

    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    ${media.isMobile`
      ${typography.body1_mobile}
    `}
  }
`;

const AnswerContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.textBlack};
  width: 100%;
  gap: 0.5em;

  > h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4_mobile}
    `}
  }

  > p {
    ${typography.body1}

    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${media.isMobile`
      ${typography.body1_mobile}
    `}
  }
`;

type ContentType = 'inquiry' | 'advice';

function extractMain(content: string, type: ContentType) {
  if (type === 'inquiry') {
    const sanitizedContent = content
      .replace(/질문 /g, '') // "질문 "을 빈 문자열로 대체
      .replace(/19세 이상 열람 가능/g, ''); // "19세 이상 열람 가능"을 빈 문자열로 대체

    const mainContent = sanitizedContent
      .split('\n') // 줄바꿈 기준으로 split
      .filter((line) => line.trim()) // 빈 줄은 제거
      .join(' '); // 남은 줄바꿈은 스페이스로 대체

    return mainContent;
  }
  const paragraphs = content.split('\n\n');

  const firstParagraph = paragraphs[0];
  const firstSentenceEndIndex = firstParagraph.indexOf('. ') + 2;

  return firstParagraph.substring(firstSentenceEndIndex);
}

export default function Case({ consultationCase, isFirstItem }: {
  consultationCase: ConsultationCase,
  isFirstItem: boolean,
}) {
  const navigate = useNavigate();

  const {
    id, userEmail, createdAt, inquiry, advice,
  } = consultationCase;

  const handleClick = () => {
    navigate(`/consultations/${id}`);
  };

  return (
    <Container id="consultation-case" isFirstItem={isFirstItem} onClick={handleClick}>
      <InquiryContainer>
        <Sidebar />
        <InquiryContent>
          <h4>
            {inquiry.title}
          </h4>
          <UserInfo>
            <p>{userEmail}</p>
            <p>{createdAt}</p>
          </UserInfo>
          <p>{extractMain(inquiry.content, 'inquiry')}</p>
        </InquiryContent>
      </InquiryContainer>
      <AnswerContainer>
        <AnswerSidebar />
        <AnswerContent>
          <h4>벤자민 - AI 변호사</h4>
          <p>{extractMain(advice, 'advice')}</p>
        </AnswerContent>
      </AnswerContainer>
    </Container>
  );
}
