import { ChangeEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import media from '../styles/media';
import palette from '../styles/palette';
import typography from '../styles/typography';

import useAuth from '../hooks/useAuth';
import useAdditionalConsultation from '../hooks/useAdditionalConsultation';

import { SidebarContainer, SidebarIcon as CommonSidebarIcon, SidebarLine } from './Sidebar';
import QuestionSubmit from './QuestionSubmit';
import TextLengthMonitor from './TextLengthMonitor';
import useConsultation from '../hooks/useConsultation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const QuestionInput = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SidebarIcon = styled(CommonSidebarIcon)`
  ${typography.heading2}

  ${media.isMobile`
    ${typography.heading2_mobile}
  `}
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;
  width: 100%;
  
  label {
    display: flex;
    align-items: center;
    gap: 0.6em;
    
    h4 {
      ${typography.heading4}
      color: ${palette.textBlack};

      ${media.isMobile`
        ${typography.heading4_mobile}
      `}      
    }

    p {
      ${typography.caption}
      color: ${palette.textBlack};

      ${media.isMobile`
        ${typography.caption_mobile}
      `}            
    }
  }

  textarea {
    ${typography.body1}
    color: ${palette.textBlack};
    padding: 0;
    resize: none;
    border: none;
    outline: none;
    width: 100%;

    ${media.isMobile`
      ${typography.body1_mobile}
    `}
  }
`;

export default function QuestionForm({
  order,
  onSubmit,
  isConsultationAuthorized,
}: {
  order: number;
  onSubmit: () => Promise<void>;
  isConsultationAuthorized: boolean;
}) {
  const { isLoggedIn } = useAuth();
  const { userEmail } = useConsultation();
  const {
    question, updateQuestion, questionMaxLength, recommendedQuestionLength,
  } = useAdditionalConsultation();
  const mobileActionButtonHeight = 70;
  const [questionFormRef, isInViewport] = useInView({ threshold: 0, rootMargin: `0px 0px -${mobileActionButtonHeight}px 0px` });

  const questionPlaceholder = isLoggedIn() ? '추가 질문을 입력하세요' : '추가 질문을 하려면 로그인하세요.';

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateQuestion(event.target.value);
  };

  if (!isConsultationAuthorized) {
    return null;
  }

  return (
    <Container ref={questionFormRef}>
      <QuestionInput>
        <SidebarContainer>
          <SidebarIcon>
            Q
            {order}
          </SidebarIcon>
          <SidebarLine />
        </SidebarContainer>
        <Input>
          <label htmlFor="question">
            <h4>
              추가 질문
            </h4>
            <p>
              {userEmail}
            </p>
          </label>
          <TextareaAutosize
            cacheMeasurements
            minRows={3}
            id="question"
            name="question"
            value={question}
            placeholder={questionPlaceholder}
            onChange={handleChange}
          />
          <TextLengthMonitor
            text={question}
            maxLength={questionMaxLength}
            recommendedLength={recommendedQuestionLength}
          />
        </Input>
      </QuestionInput>
      <QuestionSubmit
        onSubmit={onSubmit}
        questioning={isInViewport}
        isEmpty={question === ''}
      />
    </Container>
  );
}
