import styled from 'styled-components';
import typography from '../styles/typography';
import palette from '../styles/palette';

const businessUrls = {
  일반이용약관: 'https://elementsworkspace.notion.site/4b4c57bdc06742c8af8b9cbadaca1eb2',
  개인정보취급방침: 'https://elementsworkspace.notion.site/105bf7a6efb84c4eb3b9b4610939ab03?pvs=74',
  사업자정보: 'https://elementsworkspace.notion.site/cb401226f5144180bc3214116c8d0def?pvs=74',
};

const Container = styled.div`
  width: 100vw;
  padding-block: 1em;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  a, p, div {
      ${typography.body3Bold}
      color: ${palette.textLightGrey};
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BusinessLinks = styled.div`
  display: flex;
  gap: 1em;
`;

const CopyRight = styled.div`
  display: flex;
  gap: 1em;

  @media (max-width: 768px) {
    div {
      display: none;
    }
  }
`;

export default function Footer() {
  return (
    <Container>
      <BusinessLinks>
        <a href={businessUrls.일반이용약관} target="_blank" rel="noreferrer">
          이용약관
        </a>
        <div>|</div>
        <a href={businessUrls.개인정보취급방침} target="_blank" rel="noreferrer">
          개인정보처리방침
        </a>
        <div>|</div>
        <a href={businessUrls.사업자정보} target="_blank" rel="noreferrer">
          사업자정보
        </a>
      </BusinessLinks>
      <CopyRight>
        <div>|</div>
        <p>
          2024 (주)엘리먼츠 All Rights Reserved.
        </p>
      </CopyRight>
    </Container>
  );
}
