import { useCallback, useState } from 'react';
import * as R from 'ramda';

import fetchUserConsultations from '../service/fetchUserConsultations';

import ConsultationListItem from '../types/ConsultationListItem';

function formatDate(date : string) {
  // date = yyyy.mm.dd
  const [year, month, day] = date.split('.');

  return `${year.slice(2)}년${month}월${day}일`;
}

const sortByTime = (consultations: ConsultationListItem[]) => {
  const descendSort = R
    .sort(R.descend(
      (consultation) => consultation.createdAt.getTime(),
    ), consultations);

  return descendSort;
};

const parseDate = (key : string) => {
  const [year, month, day] = key.split('.').map(Number);
  return new Date(year, month - 1, day);
};

const sortByDate = (groups : Record<string, ConsultationListItem[]>) => {
  const dates = Object.keys(groups);

  const sortedDates = R.sort<string>(R.descend(parseDate))(dates);

  const sortedGroups = sortedDates.reduce<Record<string, ConsultationListItem[]>>((acc, date) => {
    acc[formatDate(date)] = groups[date];

    return acc;
  }, {});

  return sortedGroups;
};

const extractDate = (consultation: ConsultationListItem) => (
  consultation.createdAt.toLocaleDateString()
);

const groupConsultationsByDate = (consultations: ConsultationListItem[]) => {
  const byDate = R.groupBy<ConsultationListItem>(
    extractDate,
  )(consultations) as Record<string, ConsultationListItem[]>;

  const latestDateFirst = sortByDate(byDate);

  const latestTimeFirst = R.mapObjIndexed(sortByTime, latestDateFirst);

  return latestTimeFirst;
};

export default function useUserConsultations() {
  const [isLoading, setIsLoading] = useState(false);
  const [userConsultations, setUserConsultations] = useState<ConsultationListItem[]>([]);

  const loadUserConsultations = useCallback(async () => {
    setIsLoading(true);
    const list = await fetchUserConsultations();
    setUserConsultations(list);
    setIsLoading(false);
  }, []);

  const dateGroups = groupConsultationsByDate(userConsultations);

  const dates = Object.keys(dateGroups);

  return {
    isLoading,
    dateGroups,
    dates,
    loadUserConsultations,
  };
}
