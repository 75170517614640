import styled from 'styled-components';

import media from '../styles/media';
import palette from '../styles/palette';

import InquiryInput from './InquiryInput';
import InquirySubmit from './InquirySubmit';

import useMainConsultation from '../hooks/useMainConsultation';

const InquiryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;
  gap: 2em;

  ${media.isMobile`
    padding: 1.25em 0.5em;
    border: 0;
    border-radius: 0;
    gap: 1em;
  `}
`;

export default function InquiryForm() {
  const { consult, inquiry } = useMainConsultation();

  return (
    <InquiryContainer>
      <InquiryInput />
      <InquirySubmit onSubmit={consult} isEmpty={inquiry.content === ''} />
    </InquiryContainer>
  );
}
