import ConsultationListItem from '../types/ConsultationListItem';

import bokeAPI from './bokeAPI';

const { log } = console;

export default async function fetchUserConsultations(): Promise<ConsultationListItem[]> {
  try {
    // TODO : axios interceptor로 accessToken header에 넣기
    const { data } = await bokeAPI.get<ConsultationListItem[]>('/consultations/user');

    const consultations = data.map((consultation) => ({
      ...consultation,
      createdAt: new Date(consultation.createdAt),
    }));

    return consultations;
  } catch (error) {
    log({ error });

    return [];
  }
}
