import { ChangeEvent } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import typography from '../styles/typography';
import media from '../styles/media';
import palette from '../styles/palette';

import useMainConsultation from '../hooks/useMainConsultation';
import useResponsive from '../hooks/useResponsive';

import Sidebar from './Sidebar';
import TextLengthMonitor from './TextLengthMonitor';

const Container = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;

  textarea {
    ${typography.body1}
    resize: none;
    border: none;
    width: 100%;
    outline: none;
    ${media.isMobile`
      ${typography.body1_mobile}
    `}
  }

  h2 {
    ${typography.heading3}
    color: ${palette.textBlack};
    ${media.isMobile`
      ${typography.heading2_mobile}
    `}
  }
`;

export default function InquiryInput() {
  const {
    inquiry, updateContent, recommendedContentLength, contentMaxLength,
  } = useMainConsultation();

  const { isMobile } = useResponsive();

  const guideline = isMobile
    ? `1. 시간 순서에 따라 육하원칙에 맞춰 작성해주세요.

2. 150자 이상으로 충분히 작성해주세요.

3. 개인정보는 입력하지 마세요.
질문 내용은 공개될 수 있습니다.

작성 후 질문하기를 누르시면 답변해드립니다.`
    : `1. 시간 순서에 따라 육하원칙(누가, 누구에게, 언제, 어디서, 무엇을, 어떻게)에 맞춰 작성해주세요.
2. 150자 이상으로 충분히 작성해주세요. 문의 내용이 적으면 답변이 부정확할 수 있습니다.
3. 개인정보는 입력하지 마세요. 질문 내용은 공개될 수 있습니다.

작성 후 질문하기 버튼을 누르시면 AI 변호사 벤자민이 답변해드립니다.`;

  const handleChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    updateContent(value);
  };

  return (
    <Container>
      <Sidebar />
      <Input>
        <h2>
          아래에 문의 내용을 입력해주세요.
        </h2>
        <TextareaAutosize
          cacheMeasurements
          minRows={4}
          placeholder={guideline}
          id="inquiry-content"
          value={inquiry.content}
          onChange={handleChangeContent}
        />
        <TextLengthMonitor
          text={inquiry.content}
          maxLength={contentMaxLength}
          recommendedLength={recommendedContentLength}
        />
      </Input>
    </Container>
  );
}
