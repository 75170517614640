import styled from 'styled-components';

import throttle from 'lodash.throttle';

import useResponsive from '../hooks/useResponsive';
import useAuth from '../hooks/useAuth';

import MobileActionButton from './MobileActionButton';
import PCSubmitButton from './common/PCSubmitButton';

import assetSource from '../data/assetSource';

const WebContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

export default function QuestionSubmit({ onSubmit, questioning, isEmpty } : {
  onSubmit: () => Promise<void>;
  questioning: boolean;
  isEmpty: boolean;
}) {
  const { isMobile } = useResponsive();
  const { isLoggedIn } = useAuth();

  const status = () => {
    if (!questioning) return 'isReading';
    if (!isLoggedIn()) return 'needLogin';
    if (isEmpty) return 'isEmpty';
    return 'question';
  };

  if (isMobile) {
    return <MobileActionButton status={status()} onSubmit={onSubmit} />;
  }

  const handleClick = throttle(async () => {
    await onSubmit();
  }, 1000);

  if (!isLoggedIn()) {
    return (
      <WebContainer>
        <PCSubmitButton
          id="login-button"
          className="for-question"
          type="button"
          onClick={handleClick}
          inactive={false}
        >
          추가질문을 위해 로그인하기
        </PCSubmitButton>
      </WebContainer>
    );
  }

  return (
    <WebContainer>
      <PCSubmitButton
        type="button"
        onClick={handleClick}
        inactive={isEmpty}
      >
        <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
        {isEmpty ? '내용을 입력해주세요' : '질문하기'}
      </PCSubmitButton>
    </WebContainer>
  );
}
