import { generateMedia } from 'styled-media-query';

export const breakPoint = '768px';

const device = generateMedia({
  breakPoint,
});

const media = {
  isMobile: device.lessThan('breakPoint'),
};

export default media;
