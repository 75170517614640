import {
  Route, Routes, BrowserRouter,
} from 'react-router-dom';

import { useEffect } from 'react';

import LoginPage from './pages/LoginPage';
import ConsultationsPage from './pages/ConsultationsPage';
import ConsultationPage from './pages/ConsultationPage';
import OAuthPage from './pages/OAuthPage';

import PageLayout from './components/layouts/PageLayout';
import useVisit from './hooks/useVisit';
import useResponsive from './hooks/useResponsive';

import HomePage from './pages/HomePage';

export default function Router() {
  const { visit } = useVisit();
  const { isMobile } = useResponsive();

  useEffect(() => {
    setTimeout(visit, 3000);
  }, [visit]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout isPcHomepage={!isMobile} withFooter={!isMobile} />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        <Route path="/" element={<PageLayout isPcHomepage={false} withFooter={!isMobile} />}>
          <Route path="/consultations/:consultationId" element={<ConsultationPage />} />
        </Route>
        <Route path="/" element={<PageLayout isPcHomepage={false} withFooter />}>
          <Route path="/consultations" element={<ConsultationsPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/oauth" element={<OAuthPage />} />
      </Routes>
    </BrowserRouter>
  );
}
