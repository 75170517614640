import styled from 'styled-components';

import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

import { SidebarContainer, SidebarIcon as CommonSidebarIcon, SidebarLine } from './Sidebar';

import useConsultation from '../hooks/useConsultation';

const Container = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SidebarIcon = styled(CommonSidebarIcon)`
  ${typography.heading2}

  ${media.isMobile`
    ${typography.heading2_mobile}
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25em;
  
  div {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    color: ${palette.textBlack};
    ${typography.body1}
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6em;

  h4 {
    ${typography.heading4}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.heading4_mobile}
    `}
  }

  p {
    ${typography.caption}
    color: ${palette.textBlack};
  }
`;

export default function Question(
  {
    order,
    content,
  }:
  {
    order: number;
    content: string;
  },
) {
  const { userEmail } = useConsultation();

  return (
    <Container>
      <SidebarContainer>
        <SidebarIcon>
          Q
          {order}
        </SidebarIcon>
        <SidebarLine />
      </SidebarContainer>
      <Content>
        <Title>
          <h4>
            추가 질문
          </h4>
          <p>
            {userEmail}
          </p>
        </Title>
        <div>
          {content}
        </div>
      </Content>
    </Container>
  );
}
