import axios from 'axios';
import retrieveAccessToken from './retrieveAccessToken';

const { BOKE_API_URL } = process.env;

const bokeAPI = axios.create({
  baseURL: BOKE_API_URL,
});

bokeAPI.interceptors.request.use((config) => {
  const ACCESS_TOKEN = retrieveAccessToken();

  if (ACCESS_TOKEN) {
    config.headers.setAuthorization(`Bearer ${ACCESS_TOKEN}`);
  }

  return config;
});

export default bokeAPI;
