import styled from 'styled-components';

import media from '../../styles/media';

const HomePageSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  ${media.isMobile`
    gap: 0.5em;
  `}
`;

export default HomePageSectionLayout;
