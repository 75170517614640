import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { createPortal } from 'react-dom';
import {
  RefCallback,
  useCallback,
  useState,
} from 'react';
import palette from '../styles/palette';
import typography from '../styles/typography';

import assetSource from '../data/assetSource';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0.5em;
  border-top: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.30);
`;

const buttonBackgroundColor: Record<Status, string> = {
  loading: palette.containerLightGrey,
  isEmpty: palette.containerLightGrey,
  isReading: palette.tint9,
  isHomepage: palette.primary,
  inquiry: palette.primary,
  question: palette.primary,
  needLogin: palette.primary,
};

const Button = styled.button<{ status : Status }>`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 16px;

  border-radius: 5px;
  ${typography.heading2_mobile}

  color: ${({ status }) => (status === 'isReading' ? palette.textDarkGrey : palette.textWhite)};
  background-color: ${({ status }) => buttonBackgroundColor[status]};
`;

const Blank = styled.div<{ height: number }>`
  width: 100vw;
  height: ${({ height }) => `${height}px`};
`;

type Status = 'loading' | 'isHomepage' | 'inquiry' | 'question' | 'needLogin' | 'isReading' | 'isEmpty';

function MobileActionButton({ status, onSubmit, containerRef }: {
  status: Status;
  onSubmit: () => void;
  containerRef: RefCallback<HTMLDivElement>;
}) {
  const handleClick = throttle(() => {
    onSubmit();
  }, 1000);

  if (status === 'loading') {
    return (
      <Container ref={containerRef}>
        <Button type="button" disabled status={status}>
          답변 중...
        </Button>
      </Container>
    );
  }

  if (status === 'isHomepage') {
    return (
      <Container ref={containerRef}>
        <Button id="start-inquiry-button" type="button" status={status} onClick={handleClick}>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="질문하러 가기" />
          <span>
            질문하러 가기
          </span>
        </Button>
      </Container>
    );
  }

  if (status === 'needLogin') {
    return (
      <Container ref={containerRef}>
        <Button type="button" status={status} onClick={handleClick}>
          추가 질문을 위해 로그인
        </Button>
      </Container>
    );
  }

  if (status === 'isReading') {
    return (
      <Container ref={containerRef}>
        <Button type="button" disabled status={status}>
          <img src={`${assetSource}/assets/scroll_down_darkgrey.svg`} alt="스크롤 내려 추가질문하기" />
          <span>
            스크롤 내려 추가질문하기
          </span>
        </Button>
      </Container>
    );
  }

  if (status === 'isEmpty') {
    return (
      <Container ref={containerRef}>
        <Button type="button" disabled status={status}>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
          <span>
            내용을 입력해주세요
          </span>
        </Button>
      </Container>
    );
  }

  return (
    <Container ref={containerRef}>
      <Button type="button" onClick={handleClick} status={status}>
        <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
        <span>
          {status === 'inquiry' ? '질문하기' : '추가 질문하기'}
        </span>
      </Button>
    </Container>
  );
}

export default function Proxy({ status, onSubmit }: {
  status: Status;
  onSubmit: () => void;
}) {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useCallback((element: HTMLDivElement | null) => {
    if (!element) {
      return;
    }
    setContainerHeight(element.offsetHeight);
  }, []);
  return (
    <>
      <MobileActionButton containerRef={containerRef} status={status} onSubmit={onSubmit} />
      {createPortal(<Blank height={containerHeight} />, document.body)}
    </>
  );
}
