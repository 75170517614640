import styled from 'styled-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import media from '../styles/media';

import useConsultation from '../hooks/useConsultation';

import InquirySection from '../components/InquirySection';
import AnswerSection from '../components/AnswerSection';
import Loading from '../components/common/PageLoading';

import scrollToBottom from '../utils/scrollToBottom';
import useAuth from '../hooks/useAuth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  ${media.isMobile`
    gap: 0em;
  `}
`;

export default function ConsultationPage() {
  const { consultationId } = useParams();

  const {
    loadConsultation, isLoading, isAdvising, isAnswering,
  } = useConsultation();

  const { authorizeConsultation, isConsultationAuthorized } = useAuth();

  useEffect(() => {
    if (isAdvising || isAnswering) {
      scrollToBottom();
    }

    return () => {
      if (isAnswering) {
        scrollToBottom();
      }
    };
  }, [isAdvising, isAnswering]);

  useEffect(() => {
    if (!consultationId) {
      // TODO: 오류 처리
      return () => {};
    }

    const loadSubscription = loadConsultation(consultationId);

    return () => {
      if (loadSubscription) {
        loadSubscription.unsubscribe();
      }
    };
  }, [consultationId, loadConsultation]);

  useEffect(() => {
    if (consultationId) {
      authorizeConsultation(consultationId);
    }
  }, [authorizeConsultation, consultationId]);

  if (isLoading) {
    return (
      <Loading />
    );
  }
  return (
    <Container className={isConsultationAuthorized ? 'my-consultation' : 'not-my-consultation'}>
      <InquirySection />
      <AnswerSection isConsultationAuthorized={isConsultationAuthorized} />
    </Container>
  );
}
