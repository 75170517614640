import styled from 'styled-components';

import assetSource from '../../data/assetSource';

import typography from '../../styles/typography';
import palette from '../../styles/palette';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;

  h3 {
    ${typography.heading3}
    color: ${palette.textBlack};
  }
`;

const LogoImage = styled.img`
  width: 2.185em;
  height: auto;
`;

export default function SectionTitle({ title }: { title: string; }) {
  return (
    <Container>
      <LogoImage src={`${assetSource}/assets/logo-default.png`} />
      <h3>{title}</h3>
    </Container>
  );
}
