import styled from 'styled-components';

import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

import { AnswerSidebar } from './Sidebar';

const Container = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25em;

  white-space: pre-wrap;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4_mobile}
    `}
  }

  div {
    color: ${palette.textBlack};
    ${typography.body1}

    ${media.isMobile`
      ${typography.body1_mobile}
    `}    
  }

  p {
    color: ${palette.textGrey};
    ${typography.caption}

    ${media.isMobile`
      ${typography.caption_mobile}
    `}    
  }
`;

export default function Answer({ content, isAdvice }: { content: string; isAdvice: boolean; }) {
  if (!content) {
    return null;
  }

  return (
    <Container>
      <AnswerSidebar />
      <Content>
        <h4>벤자민 - AI 변호사</h4>
        <div>
          {content}
        </div>
        {
          isAdvice
            ? <p>※ 본 상담은 AI 상담으로 참고용이며, 구체적인 사안에 적용하는 경우 반드시 법률분야 종사자의 도움을 받으시기 바랍니다.</p>
            : null
        }
      </Content>
    </Container>
  );
}
