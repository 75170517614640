import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import consultationState from '../stores/consultationState';

import Inquiry from '../types/Inquiry';

import createConsultation from '../service/createConsultation';

import useModal from './useModal';
import InquiryContentModal from '../components/modal/InquiryContentModal';
import useConsultation from './useConsultation';
import useVisit from './useVisit';

export default function useMainConsultation() {
  const [consultation, setConsultation] = useRecoilState(consultationState);
  const { openModal } = useModal();
  const { guestId } = useVisit();

  const { startLoading } = useConsultation();

  const navigate = useNavigate();
  const contentMaxLength = 2000;
  const recommendedContentLength = 150;

  const updateInquiry = useCallback((setter : (prev : Inquiry) => Inquiry) => {
    setConsultation((prev) => ({
      ...prev,
      mainConsultation: {
        ...prev.mainConsultation,
        inquiry: setter(prev.mainConsultation.inquiry),
      },
    }));
  }, [setConsultation]);

  const updateContent = useCallback((content: string) => {
    if (content.length > contentMaxLength) {
      const truncatedText = content.substring(0, contentMaxLength);
      updateInquiry((prev) => ({
        ...prev,
        content: truncatedText,
      }));
      return;
    }

    updateInquiry((prev) => ({
      ...prev,
      content,
    }));
  }, [updateInquiry]);

  const consult = async () => {
    const { content } = consultation.mainConsultation.inquiry;

    const startConsultation = async () => {
      startLoading();
      const id = await createConsultation(content, guestId);
      navigate(`/consultations/${id}`);
    };

    if (content.length < recommendedContentLength) {
      openModal({
        Component: InquiryContentModal,
        onSubmit: startConsultation,
      });
      return;
    }

    await startConsultation();
  };

  const { isAdvising } = consultation;
  const { inquiry, advice } = consultation.mainConsultation;

  return {
    inquiry,
    advice,
    isAdvising: isAdvising && !advice,
    isMainConsultation: Boolean(!advice),
    recommendedContentLength,
    contentMaxLength,
    updateContent,
    consult,
  };
}
