import ConsultationCase from '../types/ConsultationCase';

import bokeAPI from './bokeAPI';

import formatCreatedAt from '../utils/formatCreatedAt';
import ConsultationCasesResponse from '../types/ConsultationCasesResponse';

const { log } = console;

export default async function fetchPaginatedConsultations(
  { page, createdAtBefore }:
  { page: number; createdAtBefore: string; },
): Promise<ConsultationCasesResponse> {
  try {
    const { data } = await bokeAPI.get<ConsultationCasesResponse>(
      `/consultations?page=${page}&createdAtBefore=${createdAtBefore}`,
    );

    const { isLastPage, consultationCases } = data;

    return {
      isLastPage,
      consultationCases: consultationCases.map((consultationCase: ConsultationCase) => ({
        ...consultationCase,
        createdAt: formatCreatedAt(new Date(consultationCase.createdAt)),
      })),
    };
  } catch (error) {
    log({ error });

    return { isLastPage: false, consultationCases: [] };
  }
}
