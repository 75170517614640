import { useMediaQuery } from 'usehooks-ts';

import { breakPoint } from '../styles/media';

export default function useResponsive() {
  const isMobile = useMediaQuery(`(max-width: ${breakPoint})`);

  return {
    isMobile,
  };
}
